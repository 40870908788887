body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-date-picker .MuiPaper-root {
  border-radius: 16px;
}

.custom-date-picker .MuiPickersLayout-root {
  background-color: #edf3f7;
  border-radius: 16px;
}

.custom-date-picker .Mui-selected {
  background-color: #bbd0e2 !important;
  color: #212121 !important;
}

.custom-date-picker .MuiPickersCalendarHeader-label {
  color: #024986;
  font-size: 22px;
  font-weight: 600;
}

.custom-date-picker .MuiButtonBase-root {
  color: #212121;
  font-weight: 600;
}

video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  overflow: hidden;
  border-radius: 7px;
}

.toast .MuiPaper-root {
  display: flex;
  align-items: center;
  padding: 5px 16px;
}

.toast .MuiAlert-icon {
  margin-right: 0;
}

#my-drbar-container > :not(:first-child) {
  display: none !important;
}

.drbar-container {
  --slider-wh: 10px !important;
  --range-thick: 10px !important;
  position: relative;
  height: 8px;
  align-items: start;
}
.drbar-container .drbar-slider {
  height: 10px !important;
  width: 12px !important;
}
